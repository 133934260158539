<template>
    <div v-if="cmsBlock" class="component-container" :class="cmsBlock.galleryStyle">
        <div class="image-gallery-wrapper">
            <div class="image-gallery" ref="imageGallery">
                <div class="slide" v-for="(slide, index) in cmsBlock.imageSlides" :key="slide.id">
                    <div class="slide-image-container">
                        <picture>
                            <template v-for="[format, set] of Object.entries(srcsets[index])">
                                <source v-if="format !== originalFormats[index]" :srcset="set" sizes="100vw"
                                    :type="'image/' + format.slice(1)">
                            </template>
                            <img class="slide-image" :srcset="srcsets[index][originalFormats[index]]" @load="onImgLoad"
                                :type="'image/' + originalFormats[index].slice(1)" sizes="100vw"
                                :alt="slide.image.data.attributes.alternativeText">
                        </picture>
                        <Copyright v-if="hasCopyright(slide) && isMobile()" :text="slide.copyright" />
                    </div>
                    <div class="slide-details-wrapper" v-if="localize(slide.caption)">
                        <div class="slide-details-content">
                            <div class="slide-text" v-html="md(localize(slide.caption))"></div>
                        </div>
                    </div>
                    <Copyright v-if="hasCopyright(slide) && !isMobile()" :text="slide.copyright" />
                </div>
            </div>
            <div class="slider-ui">
                <div class="slider-arrows">
                    <div class="slider-prev" v-on:click="prevSlide">
                        <img src="@/assets/arrow_left.svg" width="21px" height="40px" alt="Previous Slide" />
                    </div>
                    <div class="slider-next" v-on:click="nextSlide">
                        <img src="@/assets/arrow_right.svg" width="21px" height="40px" alt="Next Slide" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from 'flickity'
import 'flickity-fullscreen'
import Copyright from '@/components/PageBuilder/Elements/Copyright'
import { isMobile } from '@/helper'


export default {
    name: 'ImageGallery',

    props: [
        'cmsBlock'
    ],

    components: {
        Copyright
    },

    data() {
        return {
            slider: null
        }
    },

    computed: {
        srcsets() {
            let srcsets = []
            for (const slide of this.cmsBlock.imageSlides) {
                srcsets.push(this.getImageSrcsets(slide.image.data.attributes))
            }
            return srcsets
        },

        hasWebp() {
            let hasWebps = []
            for (const srcset of this.srcsets) {
                hasWebps.push(srcset['.webp'] ? true : false)
            }
            return hasWebps
        },

        originalFormats() {
            let originalFormats = []
            for (const srcset of this.srcsets) {
                for (const [format, _] of Object.entries(srcset)) {
                    if (format !== '.webp') {
                        originalFormats.push(format)
                    }
                }
            }

            return originalFormats
        },
    },

    mounted() {
        var self = this
        this.$nextTick(() => {
            self.slider = new Flickity(self.$refs.imageGallery, {
                prevNextButtons: false,
                pageDots: false,
                autoPlay: 7000,
                wrapAround: true,
                pauseAutoPlayOnHover: false,
                imagesLoaded: true,
                lazyLoad: true
            })
        })
    },

    methods: {
        onImgLoad() {
            this.slider.resize()
        },

        hasCopyright(slide) {
            return slide.copyright ? slide.copyright.length > 0 : false
        },

        isMobile: isMobile,

        prevSlide() {
            this.slider.previous()
        },

        nextSlide() {
            this.slider.next()
        },

        slideActive(slide) {
            if (this.slider) {
                return {
                    active: this.slider.selectedIndex == slide - 1
                }
            }
        },

        selectSlide(slide) {
            this.slider.select(slide - 1)
        }

    }
}
</script>

<style lang="scss">
.component-container {

    .image-gallery-wrapper {
        position: relative;
        width: 100%;
        max-width: $container-width;
        height: unset;
        aspect-ratio: 16/9;
        margin: 0 auto;


        .image-gallery {
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;

            .flickity-viewport,
            .flickity-slider {
                height: 100% !important;
            }

            .slide {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;

                .slide-image-container {
                    position: relative;
                    height: 100%;
                }

                .slide-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .slide-details-wrapper {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    display: flex;
                    background: rgba(0, 0, 0, 0.5);
                    padding: 20px;

                    @include breakpoint('mobile') {
                        //position: relative;
                        //background: transparent;
                        padding: 5px;
                    }

                    .slide-details-content {
                        //max-width: 1350px;
                        margin: 0 auto;
                        display: flex;

                        .slide-text {
                            margin-left: 50px;
                            font-size: getFontSize('smallText', 'desktop');
                            font-family: getFontFamily('smallText');
                            text-align: start;
                            margin: 5px 0px;

                            @include breakpoint('tablet') {
                                font-size: getFontSize('smallText', 'tablet');
                            }

                            @include breakpoint('mobile') {
                                font-size: getFontSize('smallText', 'mobile');
                            }

                            @include breakpoint('mobile') {
                                margin-left: 0;
                            }

                            p {
                                font-size: getFontSize('smallText', 'desktop');
                                font-family: getFontFamily('smallText');
                                text-align: start;
                                margin: 5px 0px;

                                @include breakpoint('tablet') {
                                    font-size: getFontSize('smallText', 'tablet');
                                }

                                @include breakpoint('mobile') {
                                    font-size: getFontSize('smallText', 'mobile');
                                }
                            }

                            strong {
                                font-size: getFontSize('smallText', 'desktop');

                                @include breakpoint('tablet') {
                                    font-size: getFontSize('smallText', 'tablet');
                                }

                                @include breakpoint('mobile') {
                                    font-size: getFontSize('smallText', 'mobile');
                                }
                            }

                            a {
                                font-size: getFontSize('smallText', 'desktop');

                                @include breakpoint('tablet') {
                                    font-size: getFontSize('smallText', 'tablet');
                                }

                                @include breakpoint('mobile') {
                                    font-size: getFontSize('smallText', 'mobile');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
